import styled from 'styled-components';
import { convertNumberToPixel } from '../../../../utils/getSizes';

export const IFrameContainer = styled.div`
  position: fixed;
  top: ${({ topPosition }) => topPosition && convertNumberToPixel(topPosition)};
  left: ${({ leftPosition }) =>
    leftPosition && convertNumberToPixel(leftPosition)};
  width: ${({ width }) => width && convertNumberToPixel(width)};
  height: ${({ height }) => height && convertNumberToPixel(height + 54)};
  z-index: 9998;

  .iframe-close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    position: absolute;
    top: 0px;
    right: 20px;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    z-index: 10000;
    background-color: #c1c1c1;
    background-color: ${({ backgroundColor }) => backgroundColor || '#c1c1c1'};
    border-radius: 0 0 10px 10px;
    max-width: 150px;

    & p {
      color: white;
      padding: 0;
    }
  }

  .iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 9999;
    border: none;
  }
`;
